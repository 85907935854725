import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import useState from '../../hooks/state';
import Anchor from '../Anchor/Anchor';
import styles from './Sidebar.module.scss';
import MenuIcon from './MenuIcon';

const Sidebar = () => {
  const { isOpen, toggle } = useState('sidebar');
  const NavLink = (typeof window !== 'undefined' && (window.location.pathname === '' || window.location.pathname)) === '/' ? Anchor : Link;

  return (
    <>
      <button aria-label="Menü öffnen" className={styles.toggle} onClick={toggle}>
        <MenuIcon isOpen={isOpen} />
      </button>
      <aside className={styles.sidebar}>
        <nav className={styles.navigation}>
          <Link to="/online-menue-planer" className={`button button--ghost-light ${styles.quickLink}`} onClick={toggle}>Menü-Planer</Link>

          {/* <Link to="/weihnachts-menue" onClick={toggle} className={styles.link}>Weihnachtsmenü</Link> */}
          <NavLink to="/#scroll=catering" anchor="catering" onClick={toggle} className={styles.link}>Catering</NavLink>
          <NavLink to="/#scroll=menu-suggestions" anchor="menu-suggestions" onClick={toggle} className={styles.link}>Menü Vorschläge</NavLink>
          <NavLink to="/#scroll=quality-information" anchor="quality-information" onClick={toggle} className={styles.link}>Qualitätsinfo</NavLink>
          <NavLink to="/#scroll=butchers" anchor="butchers" onClick={toggle} className={styles.link}>Ladenlokal</NavLink>
          <NavLink to="/#scroll=contact" anchor="contact" onClick={toggle} className={styles.link}>Kontakt</NavLink>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
